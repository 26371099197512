<template>
	<div class="MerchantShipments">
		<w-navTab titleText="快递发货"></w-navTab>
		<div class="MerchantShipments-body">
			<div class="body">
				<div class="body-item">
					<div class="item-title">快递名称</div>
					<div class="item-input">
						<input type="text" v-model="postCompany" placeholder="请输入快递名称">
					</div>
				</div>
				<div class="body-item">
					<div class="item-title">快递单号</div>
					<div class="item-input">
						<input type="text" v-model="postNumber" placeholder="请输入快递单号">
					</div>
				</div>
				<div class="body-message">
					<div class="message-title">留言</div>
					<div class="message-text">
						<textarea v-model="content" placeholder="请输入留言内容"></textarea>
						<div class="message-text-number">
							<div>({{ content.length }}/300)</div>
						</div>
					</div>
				</div>
			</div>
			<div class="footer-button">
				<div class="button-text" @click="onSubmit">确认</div>
			</div>
		</div>
	</div>
</template>

<script>
	import WTitle from '@/components/Title';
	import VantVendor from '@/vendor/vant';
	import Merchant from '@/api/merchant';
	import ValidatorVendor from '@/vendor/validator';
	export default {
		name: 'MerchantShipments',
		data() {
			return {
				orderId: '',
				productId: '',
				postCompany: '',
				postNumber: '',
				content: '',
			}
		},
		watch: {
			content(newVal, oldVal) {
				if (newVal.length > 300) {
					this.content = this.content.substring(0, 300);
				}
			},
		},
		created() {
			this.getValidator()
			this.merchantId = this.$route.params.merchantId
			this.orderId = this.$route.params.orderId
			this.productId = this.$route.params.productId
		},
		methods: {
			getValidator() {
				this.validator = new ValidatorVendor({
					rules: {
						post_company: {
							required: true
						},
						post_number: {
							required: true
						}
					},
					messages: {
						post_company: {
							required: '快递名称不能为空'
						},
						post_number: {
							required: '快递单号不能为空'
						},
					}
				});
			},
			onSubmit() {
				let params = {
					order_id: this.orderId,
					product_id: this.productId,
					post_company: this.postCompany,
					post_number: this.postNumber,
					remark: this.content,
				}
				if (!this.validator.checkData(params)) {
					return;
				}
				VantVendor.Dialog.confirm({
						title: '发货提示',
						message: '您确认发货吗？',
					})
					.then(() => {
						Merchant.productDeliverGoods(params)
							.then(result => {
								VantVendor.Toast.success('发货成功');
								setTimeout(() => {
									this.$router.replace(`/Merchant/${this.merchantId}`)
								}, 1000)
							})
							.catch(error => {
								VantVendor.Toast.fail(error.msg);
							});
					})
			}
		},
		components: {
			WTitle
		}
	}
</script>

<style scoped lang="less">
	.MerchantShipments {
		width: 100%;
		min-height: 100vh;
		background: #F2F2F2;

		.MerchantShipments-body {
			padding: 16px 10px;
			box-sizing: border-box;

			.header-title {
				font-weight: bold;
				font-size: 18px;
				line-height: 27px;
				color: #151C39;
			}

			.body {

				.body-item {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 10px;
					box-sizing: border-box;
					background: #FFF;
					border-radius: 6px;
					margin-bottom: 10px;

					.item-title {
						font-size: 16px;
						line-height: 26px;
						color: #777;
						min-width: 70px;
					}

					.item-input {
						font-size: 16px;
						line-height: 24px;
						color: #222;

						input {
							text-align: right;
							border: 0;
						}
					}
				}

				.body-message {
					padding: 10px;
					box-sizing: border-box;
					background: #FFF;
					border-radius: 6px;
					margin-bottom: 10px;

					.message-title {
						font-size: 16px;
						line-height: 26px;
						color: #777;
						margin-bottom: 10px;
					}

					.message-text {
						font-size: 16px;
						line-height: 22px;
						color: #151C39;

						textarea {
							height: 70px;
							border: 0;
						}

						.message-text-number {
							text-align: right;
							font-size: 14px;
							line-height: 26px;
							color: #777;
						}
					}
				}
			}

			.footer-button {
				position: fixed;
				bottom: 20px;
				left: 0;
				padding: 0 10px;
				width: 100%;
				box-sizing: border-box;

				.button-text {
					padding: 10px 0;
					background: #3377FF;
					font-size: 18px;
					line-height: 26px;
					text-align: center;
					color: #FFF;
					border-radius: 100px;
					box-sizing: border-box;
				}
			}
		}

	}
</style>